<template>
  <div class="report-parameters container template-1">
    <header class="page-header container mx-lg">
      <h1>{{ translations.tcTitle ? translations.tcTitle : title }}</h1>
    </header>
    <div class="pb20">
      <camp-selector @camp_changed="page_load()" :displayOnly="true" :i18n="translations.components"></camp-selector>
    </div>
    <section class="container bg-block">
      <h3>{{ translatedName }}</h3>
      <b>{{ reportLabelDescription }}</b>
      <div>{{ reportMessage }}</div>
      <FormulateForm v-model="values" :schema="params"></FormulateForm>
      <div class="report-download-btn">
        <b-button download variant="secondary" class="flex-0 mr-0 mr-sm-3 mb-3 w-100-sd mb-sm-0"
          @click.prevent="downloadReport(reportDownloadUrl, reportName, fileType)">
          {{ translations.tcDownloadReport }}
        </b-button>
        <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
          {{ translations.tcCancel }}
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import { REPORT_CONFIG } from '../../config/report.config'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'
import { translationMixin } from '@/mixins/translationMixin'// Add styles if need
import "@sum.cumo/vue-datepicker/dist/Datepicker.css";

export default {
  name: 'report-parameters',
  mixins: [reportDownloadMixin, translationMixin],
  data() {
    return {
      translations: {},
      title: 'Report Parameters',
      values: {},
      params: [],
      reportName: '',
      translatedName: '',
      reportParamParentKey: '',
      fileType: '',
      userMemberType: '',
      campEventPlannerKey: 'd60347fe-816c-4623-afbf-e1ade3aec1c6'
    }
  },
  components: {
    campSelector: CampSelect,
  },
  methods: {
    ...mapActions({
      loadCampsByStateArea: 'reportParameter/loadCampsByStateArea',
      loadReportParameters: 'reportParameter/loadReportParameters',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    addPresenter() {
      this.$router.push('/')
    },
    async page_load() {
      try {
        var calendarLanguage = this.prefCulture.substring(0, 2)
        this.setLoadingStatus(true)
        this.userMemberType = this.userLogin.membertype
        const reportKey = this.selectedReportKey
        this.reportParamParentKey = this.reportParentKey
        this.fileType = this.reportFileType

        // for all state reports, the org_key should be the user's country key
        var reportParamOrgKey = ''

        // for state and country reports, use state key to load report parameters
        // else, user the camp key
        if (
          [
            '03fcdc2e-711c-4966-b1ed-900ce284e401',
            '9a16d6f0-bcbb-419d-8342-4ff9e102731b',
            'a67aedf3-a61d-4be5-ac3e-0471aa4881cf',
          ].includes(this.reportParamParentKey)
        ) {
          reportParamOrgKey = this.officerToolbarSelected.country_state
        } else {
          reportParamOrgKey = this.userCampKey
        }
        var data = {
          reportKey,
          reportOrgKey: reportParamOrgKey,
          userMemberType: this.userMemberType,
        }
        await Promise.all([
          await this.getViewTranslations(),
          await this.loadReportParameters(data).then(() => {
            this.params = this.reportParameters.map((p) => {
              let ddSelectedValue = p.value
              if (p.type == 'select') {
                if (!p.options.find((o) => o.value === p.value)) {
                  ddSelectedValue = p.options[0].value
                }
              }
              if (p.type == 'date') {
                p.type = 'datepicker'
                ddSelectedValue = ddSelectedValue + 'T01:02:03'
              }
              return {
                label: !!this.translations[p.sitecore] ? this.translations[p.sitecore] : p.label,
                type: p.type,
                value: ddSelectedValue,
                name: p.name,
                language: p.type === 'datepicker' ? calendarLanguage : '',
                validation: p.validation,
                'label-class': p.labelClass ? p.labelClass : null,
                options: p.options
                  ? p.options.map((o) => {
                    return {
                      value: o.value,
                      label: !!this.translations[o.sitecore] ? this.translations[o.sitecore] : o.label,
                    }
                  })
                  : null,
                'label-position': p.labelPosition ? p.labelPosition : null,
                '@change': this.refreshCampsData,
              }
            })
            // for Country Leadership report, the org key must be the logged on user's country key only if it's an IOC user
            // if it's a USA user, use the country key from the Officer Toolbar selected country
            if (reportKey === '8cefdde6-cf82-4549-adb3-3d392474d687') {
              this.params.find((pr) => pr.name == 'org_key').value = data.reportOrgKey
            }
            this.reportName = this.paramsReportName
          }),
          this.getComponentTranslations('camp-select'),
        ]).then((results) => {
          const componentTranslations = results[2]
          this.$set(this.translations, 'components', componentTranslations)
          this.translatedName = !!this.translations[this.paramsSitecoreName]
            ? this.translations[this.paramsSitecoreName]
            : this.reportName
        })
        // // if report name = Camp Event Planner Report, then set camp parameters
        if (this.selectedReportKey === this.campEventPlannerKey) {
          this.values.CampNumber = this.userLogin.campnumber
          this.values.CampName = this.officerToolbarSelected.camp_name
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleCancelClick() {
      this.$router.go(-1)
    },
    async refreshCampsData() {
      if (this.values && window.event.target.name == this.cascadeRpdName) {
        const param = {
          rpd_key: this.cascadeRpdKey,
          site_key: window.event.target.value,
        }
        this.loadCampsByStateArea(param).then(() => {
          // set options to the newly received from database
          this.params.find((p) => p.name === 'org_key').options = this.stateCamps

          // set selected value to the first item in the options
          this.values.org_key = this.stateCamps[0].value
        })
      }
    },
  },
  async created() {
  },
  computed: {
    ...mapGetters({
      cascadeRpdKey: 'reportParameter/cascadeRpdKey',
      cascadeRpdName: 'reportParameter/cascadeRpdName',
      demograph: 'user/demograph',
      officerToolbarSelected: 'user/officerToolbarSelected',
      paramsReportName: 'reportParameter/reportName',
      paramsSitecoreName: 'reportParameter/sitecoreName',
      prefCulture: 'user/userPreferredCulture',
      reportApiEndpoint: 'reportParameter/apiEndpoint',
      reportFileType: 'reportParameter/reportFileType',
      reportParameters: 'reportParameter/reportParametersList',
      reportParentKey: 'reportParameter/reportParentKey',
      selectedReportKey: 'reportParameter/selectedReportKey',
      stateCamps: 'reportParameter/stateCamps',
      userCamp: 'user/userCamp',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userLogin: 'user/userLogin',
    }),
    reportDownloadUrl() {
      const apiendpoint = this.reportApiEndpoint.replace('/report/', '')
      const route = REPORT_CONFIG.generateReportPDF(this.values)
      return route
    },
    reportMessage() {
      return this.reportName === 'State Member List Export'
        ? 'Due to the amount of information being retrieved, this file may take more than 2 minutes to load.'
        : ''
    },
    reportLabelDescription() {
      return this.reportName === 'Church Mailing Labels'
        ? this.translations.tcChurchMailingLabelDescription || 'Uses Avery label size 5160 or its equivalent'
        : ''
    },
  },
}
</script>

<style scoped>
.pb20 {
  padding-bottom: 20px;
}
</style>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

select {
  height: auto !important;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  max-width: 440px;
}

input[type='date'] {
  border: 1px solid #636363;
  padding: 13px 20px 13px 60px;
  height: auto;
  font-weight: bold;
  font-size: 16px;
}

input[type='checkbox'] {
  height: 30px;
  width: 30px;
}

.rpt-checkbox {
  font-weight: bolder;
  margin: 30px 15px 10px 0px;
}

.rpt-label {
  font-weight: bolder;
  margin-top: 30px;
}

.rpt-checkbox+div {
  display: inline-block;
}

div .report-download-btn {
  margin: 30pt 0px 10px 0px;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}
</style>
